<template>
        <div class="modal" tabindex="-1" role="dialog" id="new-performance">
            <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" class="close" aria-label="Close">
                            <span aria-hidden="true" @click="dismiss">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <h3>Line-up toevoegen</h3>
                        <div class="row">
                            <div class="col-7">
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="Titel project / optreden" v-model="performance.title"/>
                                    <p class="error" v-if="errors.title">{{errors.title[0]}}</p>
                                    <label for="ptype">Type:</label>
                                    <select id="ptype" v-model="performance.type">
                                        <option value="live">Live</option>
                                        <option value="band">Band</option>
                                        <option value="album">Album</option>
                                        <option value="livestream">Livestream</option>
                                    </select>
                                    <p class="error" v-if="errors.type">{{errors.type[0]}}</p>
                                    <div id="datepicker" class="form-group">
                                        <label>Datum:</label>
                                        <date-picker v-model="performance.date" :config="options"></date-picker>
                                        <p class="error" v-if="errors.date">{{errors.date[0]}}</p>
                                    </div>
                                    <div>
                                    <label>Ranking</label>
                                        <select v-model="performance.ranking">
                                            <option v-for="rank in [0,1,2,3,4,5,6,7,8,9,10]" :value="rank">{{rank}}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="col-5" align="center">
                                <div class="big-icon camera-icon">
                                    <img class="big-image":src="imagedata" v-if="imagedata" alt="photo"/>
                                </div>
                                <input ref="photoInput" type="file" accept="image/*" capture="camera" @change="readImage" v-if="!imagedata" />
                            </div>
                        </div>

                        <lineup-component
                            @performanceSelected="handlePerformanceSelect"
                            :bandName="bandName"
                            @nameEntered="acceptBandname"
                        ></lineup-component>

                        <div class="titled-box" v-if="performance.type != 'band'">
                            <h4>Locatie:</h4>
                            <select id="ploc" v-model="performance.location_id">
                                <option value=""></option>
                                <option v-for="location in locations" :value="location.id">{{location.name}}</option>
                            </select>
                            <input class="small" type="text" placeholder="Nieuwe locatie" v-model="performance.location.name"/>
                            <input class="small" type="text" placeholder="Plaats" v-model="performance.location.city"/>
                        </div>

                        <div class="titled-box" v-if="performance.type != 'band'">
                            <h4>Label:</h4>
                            <select id="label-id" v-model="performance.label_id">
                                <option value=""></option>
                                <option v-for="label in labels" :value="label.id">{{label.name}}</option>
                            </select>
                            <input class="small" type="text" placeholder="Nieuwe reeks" v-model="performance.label.name"/>
                            <select id="label-type" v-model="performance.label.type">
                                <option value="series">Reeks</option>
                                <option value="recordlabel">Platenlabel</option>
                                <option value="festival">Festival</option>
                            </select>
                        </div>

                        <input type="text" class="form-control" placeholder="Info" v-model="performance.info"/>

                        <musicians-component
                            :lineup="performance.musicians"
                            :editing="true"
                            :performanceId="performance.id"
                            @MusicianAdded="addMusician"
                            @MusicianRemoved="removeMusician"
                        ></musicians-component>
                        <p class="error" v-if="errors.lineup">{{errors.lineup[0]}}</p>

                        <tags-component
                            :tags="performance.tags"
                            :editing="true"
                            @tagsChanged="onTagsChanged">
                        </tags-component>

                        <urls-component
                            :urls="performance.urls"
                            :editing="true"
                            @UrlAdded="addUrl"
                            @UrlRemoved="removeUrl"
                        >
                        </urls-component>
                    </div>

                    <div class="modal-footer">
                        <button type="button" class="btn btn-default" value="" @click="dismiss">Annuleren</button>
                        <button type="button" class="btn btn-primary" value="" @click="savePerformance">Opslaan</button>
                    </div>
                </div><!-- /.modal-content -->
            </div><!-- /.modal-dialog -->
        </div><!-- /.modal -->
</template>

<script>

import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
import datePicker from 'vue-bootstrap-datetimepicker';
import MusicianInput from "./MusicianInput";
import UrlsComponent from "./UrlsComponent";
import moment from 'moment';
import MusiciansComponent from "./MusiciansComponent";
import TagsComponent from "./TagsComponent";
import LineupComponent from "./LineupComponent";
export default {
    name: "PerformanceDialogComponent",
    components: {MusiciansComponent, datePicker, MusicianInput, UrlsComponent, TagsComponent, LineupComponent },
    data(){
        return {
            performance: {},
            locations: [],
            labels: [],
            options: {
                format: 'DD-MM-YYYY',
                useCurrent: true
            },
            errors:[],
            imagedata: null,
            bandName: ''
        }
    },
    mounted(){
        $("#new-performance").modal("show");
        axios.get('/api/locations')
            .then(response => {
                if(response.data.result=='success') {
                   this.locations = response.data.locations;
                   this.labels = response.data.labels;
                } else {
                    if(response.data.message)
                        alert(response.data.message);
                }
            })
            .catch(error => {
                if(error.response.status == 401){
                    location.href='';
                } else {
                    alert(error);
                }
            });
    },
    methods: {
        acceptBandname: function (name) {
            this.performance.band_name = name;
        },
        handlePerformanceSelect: function(p) {
            // get the performance's musicians to fill-in
            axios.get('/api/performance/' + p.id)
                .then(response => {
                    if(response.data.result=='success') {
                        this.performance.musicians = response.data.performance.musicians;
                        this.bandName = p.band_name;
                    } else {
                        if(response.data.message)
                            alert(response.data.message);
                        if(response.data.errors)
                            this.errors = response.data.errors;
                    }
                })
                .catch(error => {
                    if(error.response.status == 401){
                        location.href='';
                    } else {
                        alert(error);
                    }
                });
        },
        onTagsChanged(tags){
            this.performance.tags = tags;
            this.contentChanged = true;
        },
        readImage(){
            let reader = new FileReader();
            let vm=this;
            reader.onload = function (){
                vm.imagedata = reader.result;
            }
            reader.readAsDataURL(this.$refs.photoInput.files[0]);
        },
        resetDialog(){
            this.performance = {
                type: 'live',
                date: (new moment()).format('DD-MM-YYYY'),
                location:{},
                label: {},
                musicians: [],
                urls: [],
                tags: [],
            }
        },
        dismiss() {
            $("#new-performance").modal("hide");
            this.$emit("close");
            this.$destroy();
        },
        savePerformance(){
            this.performance.imagedata = this.imagedata;
            axios.post('/api/performance', this.performance)
                .then(response => {
                    if(response.data.result=='success') {
                       this.$emit('PerformanceAdded', this.performance);
                       this.dismiss();
                    } else {
                        if(response.data.message)
                            alert(response.data.message);
                        if(response.data.errors)
                            this.errors = response.data.errors;
                    }
                })
                .catch(error => {
                    if(error.response.status == 401){
                        location.href='';
                    } else {
                        alert(error);
                    }
                });
        },
        addMusician(musician){
            this.performance.musicians.push(musician);
        },
        removeMusician(musician){
            this.performance.musicians = this.performance.musicians.filter(mus=>{if(musician.id && musician.id==mus.id){ return false; } else {return(musician.name!=mus.name)}});
        },
        addUrl(url){
            console.log(url);
            this.performance.urls.push(url);
        },
        removeUrl(url){
            this.performance.urls = this.performance.urls.filter(u=>{if(url.id && url.id==m.id){ return false; } else {return(url.uri!=u.uri)}});
        }
    },
    created() {
        this.resetDialog();
        const escHandler = e => {
            if (e.key === "Escape" && this.show) {
                this.dismiss();
            }
        };

        document.addEventListener("keydown", escHandler);
        this.$once("hook:destroyed", () => {
            document.removeEventListener("keydown", escHandler);
        });
    }
}
</script>

<style scoped>
    #datepicker{
        width: 200px;
        display: inline-block;
    }
    .error{
        color: red;
    }
    input.small{
        width: auto;
    }
</style>
