<template>
    <div class="titled-box">
        <h4>Tags</h4>
        <div class="tag-area">
            <span class="righttop-icon delete-icon" @click="reset" v-if="editing"></span>
            <span class="inline-icon edit-icon" v-if="!tags.length && editing" @click="onTagsClick"></span>
            <ul class="tagslist" @click="onTagsClick">
                <li v-for="tag in tags">{{tag.title}}&nbsp;|&nbsp;</li>
            </ul>
        </div>
        <div v-if="showModal">
            <transition name="modal">
                <div class="modal-mask">
                    <div class="modal-wrapper">
                        <div class="modal-dialog" role="document">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title">Kies tags</h5>
                                </div>
                                <div class="modal-body">
                                    <div v-for="tag in tagcollection">
                                        <input type="checkbox" :name="tag.title" :value="tag.id" :checked="tagSelected(tag)" @click="toggleTag(tag)" /><span>{{tag.title}}</span>
                                    </div>
                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-secondary" @click="cancelChanges">Annuleren</button>
                                    <button type="button" class="btn btn-primary" @click="saveChanges">Opslaan</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
export default {
    name: "TagsComponent",
    props:{
        tags:{
            type: Array,
            default: function (){ return [] }
        }, editing: {
            type: Boolean,
            default: false
        }
    },
    data: function(){
        return {
            showModal: false,
            tagcollection: [],
            newtags: [],
        }
    },
    created(){
        this.newtags = this.tags;
        axios.get('/api/tags')
            .then(response => {
                if(response.data.result=='success') {
                   this.tagcollection = response.data.tags;
                } else {
                    if(response.data.message)
                        alert(response.data.message);
                }
            })
            .catch(error => {
                if(error.response.status == 401){
                    location.href='';
                } else {
                    alert(error);
                }
            });
    },
    mounted() {
    },
    methods:{
        tagSelected(tag){
            return this.newtags.find(i=>{return i.id == tag.id});
        },
        toggleTag(tag){
            // handle checkbox click on a tag
            let tagWasSelected = this.newtags.find(i=>{return i.id == tag.id});
            if(tagWasSelected){
                this.newtags = this.newtags.filter(i=>{return i.id != tag.id});
            } else {
                this.newtags.push(tag);
            }
        },
        onTagsClick(){
            if(this.editing)
                this.showModal=true;
        },
        cancelChanges(){
            this.showModal=false;
        },
        saveChanges(){
            this.$emit('tagsChanged', this.newtags);
            this.showModal = false;
        },
        reset(){
            this.newtags = [];
            this.saveChanges();
        }
    }
}
</script>

<style scoped>
    .modal-mask {
        position: fixed;
        z-index: 9998;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, .5);
        display: table;
        transition: opacity .3s ease;
    }

    .modal-wrapper {
        display: table-cell;
        vertical-align: middle;
    }
    input[type='checkbox']{
        width: 40px;
    }
    ul.tagslist li{
        display: inline-block;
    }
    .tag-area{
        position: relative;
    }

</style>
