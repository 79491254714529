<template>
    <div class="card">
        <div class="card-header">
            <h4 v-if="!editmode">{{performance.title}}</h4>
            <input type="text" v-model="performance.title" v-if="editmode" @change="changed" />
            <span class="righttop-icon edit-icon" @click="startEditMode" v-if="!editmode" title="Wijzig deze line-up"></span>
            <span class="righttop-icon checkicon" @click="savechanges" v-if="contentChanged" title="Sla de wijzigingen op"></span>
            <span class="righttop-icon delete-icon" @click="cancelchanges" v-if="editmode" title="Annuleer de wijzigingen"></span>
        </div>
        <div class="card-body">
            <div class="row">
                <div class="col-md-6">
                    <table @dp-change="changed" @keyup="changed" @change="changed">
                        <tbody>
                            <tr>
                                <td>Type:</td>
                                <td>
                                    <span v-if="!editmode">{{performance.type}}</span>
                                    <select id="ptype" v-model="performance.type" v-if="editmode">
                                        <option value="live">Live</option>
                                        <option value="band">Band</option>
                                        <option value="album">Album</option>
                                        <option value="livestream">Livestream</option>
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <td>Datum:</td>
                                <td>
                                    <span v-if="!editmode">{{performance.date}}</span>
                                    <div id="datepicker" v-if="editmode">
                                        <date-picker v-model="performance.date" :config="options" @dp-change="changed"></date-picker>
                                        <p class="error" v-if="errors.date">{{errors.date[0]}}</p>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>Locatie:</td>
                                <td>
                                    <span v-if="!editmode">{{theLocation}}</span>
                                    <div v-if="editmode">
                                        <select id="ploc" v-model="performance.location_id" @change="newLocation={}">
                                            <option value=""></option>
                                            <option v-for="location in locations" :value="location.id">{{location.name}}</option>
                                        </select>
                                        <input type="text" placeholder="Nieuwe locatie" v-model="performance.newLocation.name" @change="performance.location_id=null"/>
                                        <input type="text" placeholder="Plaats" v-model="performance.newLocation.city" @change="performance.location_id=null"/>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>Label:</td>
                                <td>
                                    <span v-if="!editmode">{{performance.label && performance.label.name}}</span>
                                    <div v-if="editmode">
                                        <select id="label-id" v-model="performance.label_id">
                                            <option value=""></option>
                                            <option v-for="label in labels" :value="label.id">{{label.name}}</option>
                                        </select>
                                        <input type="text" placeholder="Nieuwe reeks" v-model="performance.newLabel.name"/>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>Band:</td>
                                <td>
                                    <span v-if="!editmode">{{performance.band_name}}</span>
                                    <input type="text" v-model="performance.band_name" v-if="editmode" />
                                </td>
                            </tr>
                            <tr>
                                <td>Info:</td>
                                <td>
                                    <span v-if="!editmode">{{performance.info}}</span>
                                    <textarea v-model="performance.info" v-if="editmode" />
                                </td>
                            </tr>
                            <tr>
                                <td>Ranking</td>
                                <td>
                                    <span v-if="!editmode">{{performance.ranking}}</span>
                                    <select v-if="editmode" v-model="performance.ranking">
                                        <option v-for="rank in [0,1,2,3,4,5,6,7,8,9,10]" :value="rank">{{rank}}</option>
                                    </select>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <urls-component
                        :urls="performance.urls"
                        :editing="editmode"
                        :performanceId="performance.id"
                        @UrlAdded="addUrl"
                        @UrlRemoved="removeUrl"
                    ></urls-component>

                </div>
                <div class="col-md-6">
                    <div class="photo">
                        <input v-if="editmode" ref="photoInput" type="file" accept="image/*" capture="camera" @change="readImage" />
                        <img :src="performance.photoref" />
                    </div>
                </div>
            </div>

            <tags-component
                :tags="performance.tags"
                :editing="editmode"
                @tagsChanged="onTagsChanged">
            </tags-component>

            <musicians-component
                :lineup="performance.musicians"
                :editing="editmode"
                :performanceId="performance.id"
                @MusicianAdded="addMusician"
                @MusicianRemoved="removeMusician"
                :key="componentKey"
                @update="forceRerender"
                @keyup.native="changed"
                @change.native="changed"
            ></musicians-component>

            <performances-component
                v-if="performance.band_name"
                :performances="sameperformances"
                :label="'Overige line-ups onder deze bandnaam'"
            >
            </performances-component>
        </div>
    </div>
</template>

<script>
import MusiciansComponent from "./MusiciansComponent";
import MusicianInput from "./MusicianInput";
import UrlsComponent from "./UrlsComponent";
import TagsComponent from "./TagsComponent";
import PerformancesComponent from "./PerformancesComponent";
import moment from 'moment';
import datePicker from 'vue-bootstrap-datetimepicker';
export default {
    name: "PerformanceComponent",
    props: ['PerformanceId'],
    components: {MusiciansComponent, MusicianInput, UrlsComponent, datePicker, TagsComponent, PerformancesComponent},
    computed: {
        theLocation: function () {
            if (this.performance.location) {
                return this.performance.location.name + ' ' + this.performance.location.city;
            }
        }
    },
    data(){
        return {
            performance: {},
            contentChanged: false,
            editmode: false,
            options: {
                format: 'DD-MM-YYYY',
                useCurrent: false
            },
            errors: [],
            locations: null,
            labels: null,
            componentKey: 0,
            sameperformances: [],
        }
    },
    watch: {},
    methods:{
        forceRerender() {
            this.componentKey += 1;
        },
        readImage(){
            let reader = new FileReader();
            let vm=this;
            reader.onload = function (){
                const data = {imagedata: reader.result};
                axios.post('/api/performance/' + vm.performance.id + '/photo', data)
                    .then(response => {
                        if(response.data.result=='success') {
                            vm.shapePerformance(response.data.performance);
                            vm.changed();
                        } else {
                            if(response.data.message)
                                alert(response.data.message);
                        }
                    })
                    .catch(error => {
                        if(error.response.status == 401){
                            location.href='';
                        } else {
                            alert(error);
                        }
                    });
            }
            reader.readAsDataURL(this.$refs.photoInput.files[0]);
        },
        startEditMode(){
            axios.get('/api/locations')
                .then(response => {
                    if(response.data.result=='success') {
                        this.locations = response.data.locations;
                        this.labels = response.data.labels;
                        this.editmode=true;
                    } else {
                        if(response.data.message)
                            alert(response.data.message);
                    }
                })
                .catch(error => {
                    if(error.response.status == 401){
                        location.href='';
                    } else {
                        alert(error);
                    }
                });
        },
        changed(){
            this.contentChanged = true;
            this.errors = [];
        },
        onTagsChanged(tags){
          this.performance.tags = tags;
          this.contentChanged = true;
        },
        savechanges(){
            this.errors = [];
            axios.patch('/api/performance/' + this.performance.id, this.performance)
                .then(response => {
                    if(response.data.result=='success') {
                        this.contentChanged = false;
                        this.editmode = false;
                        this.shapePerformance(response.data.performance);
                    } else {
                        if(response.data.errors) this.errors = response.data.errors;
                        if(response.data.message) alert(response.data.message);
                    }
                })
                .catch(error => {
                    if(error.response.status == 401){
                        location.href='';
                    } else {
                        alert(error);
                    }
                });
        },
        shapePerformance(raw){
            this.performance = raw;
            if(!raw.location) this.performance.location = null;
            if(!raw.label) this.performance.label = null;
            if(!raw.urls) this.performance.urls = [];
            if(!raw.tags) this.performance.tags = [];
            this.performance.newLocation = {};
            this.performance.newLabel= {};
            if(this.performance.date)
                this.performance.date = moment(this.performance.date, 'YYYY-MM-DD').format('DD-MM-YYYY');
        },
        cancelchanges(){
            this.readPerformance();
        },
        removeMusician(musician) {
            if(musician.id) {
                axios.delete('/api/performance/' + this.performance.id + '/musician/' + musician.id)
                    .then(response => {
                        if (response.data.result == 'success') {
                            this.performance.musicians = this.performance.musicians.filter(m => {
                                return (musician.id != m.id)
                            });
                        } else {
                            if (response.data.message)
                                alert(response.data.message);
                        }
                    })
                    .catch(error => {
                        if (error.response.status == 401) {
                            location.href = '';
                        } else {
                            alert(error);
                        }
                    });
            } else {
                this.performance.musicians = this.performance.musicians.filter(m => {
                    return (musician.name != m.name)
                });
            }
        },
        addMusician(musician){
            this.performance.musicians.push(musician);
            this.changed();
        },
        removeUrl(url) {
            if(url.id) {
                axios.delete('/api/performance/' + this.performance.id + '/url/' + url.id)
                    .then(response => {
                        if (response.data.result == 'success') {
                            this.performance.urls = this.performance.urls.filter(u => {
                                return (url.id != u.id)
                            });
                            this.contentChanged=true;
                        } else {
                            if (response.data.message)
                                alert(response.data.message);
                        }
                    })
                    .catch(error => {
                        if (error.response.status == 401) {
                            location.href = '';
                        } else {
                            alert(error);
                        }
                    });
            } else {
                this.performance.urls = this.performance.urls.filter(u => {
                    return (url.uri != u.name)
                });
            }
        },
        addUrl(url){
            axios.post('/api/performance/' + this.performance.id + '/url', url)
                .then(response => {
                    if(response.data.result=='success') {
                        this.performance.urls.push(response.data.url);
                        this.contentChanged=true;
                    } else {
                        if(response.data.message)
                            alert(response.data.message);
                    }
                })
                .catch(error => {
                    if(error.response.status == 401){
                        location.href='';
                    } else {
                        alert(error);
                    }
                });
        },
        readPerformance(){
            axios.get('/api/performance/' + this.PerformanceId)
                .then(response => {
                    if(response.data.result=='success') {
                        this.shapePerformance(response.data.performance);
                        this.contentChanged = false;
                        this.editmode = false;
                        this.getAlikePerformances();
                    } else {
                        if(response.data.message)
                            alert(response.data.message);
                    }
                })
                .catch(error => {
                    if(error.response.status == 401){
                        location.href='';
                    } else {
                        alert(error);
                    }
                });

        },
        getAlikePerformances(){
            if(this.performance.band_name) {
                let data = {band_name: this.performance.band_name};
                axios.get('/api/performances', {params: data})
                    .then(response => {
                        if (response.data.result == 'success') {
                            this.sameperformances = response.data.performances.data;
                        } else {
                            if (response.data.message)
                                alert(response.data.message);
                        }
                    })
                    .catch(error => {
                        if (error.response.status == 401) {
                            location.href = '';
                        } else {
                            alert(error);
                        }
                    });
            }
        }
    },
    mounted() {
        this.readPerformance();
    }
}
</script>

<style>
    .photo img{
        width: 100%
    }
    input,textarea{width: 100%}
    table{width: 100%}
</style>
