<template>
    <div class="titled-box">
        <h4>Line-up</h4>
        <table class="table-striped">
            <thead>
                <th>Naam</th>
                <th>Instrumenten Muzikant</th>
                <th>Instrumenten Performance</th>
                <th>Gast</th>
                <th>Leider</th>
                <th v-if="editing"></th>
            </thead>
            <tbody>
                <tr v-for="musician in lineup" :key='musician.id' :class="{leadingartist: musician.pivot.leading_artist}">
                    <td>
                        <p class="musician" :class="{link: !editing}" @click="showMusician(musician)">
                            {{musician.name}}
                        </p>
                    </td>
                    <td>
                        <div class="instruments-cell" v-if="selectedMusician.name != musician.name" @click="editInstruments(musician)">
                            <span v-for="instrument in musician.instruments">{{instrument.name}}, </span>
                        </div>
                        <instrument-picker
                            v-if="selectedMusician.name == musician.name"
                            @pickedInstruments="pickInstrumentsMusician"
                            :selectedInstruments="musician.instruments"
                        >
                        </instrument-picker>
                    </td>
                    <td>
                        <div class="instruments-cell" v-if="selectedMusician.name != musician.name" @click="editInstruments(musician)">
                            <span v-if="musician.pivot.instrumentcode1">{{musician.pivot.instrument1}}</span>
                            <span v-if="musician.pivot.instrumentcode2">, {{musician.pivot.instrument2}}</span>
                            <span v-if="musician.pivot.instrumentcode3">, {{musician.pivot.instrument3}}</span>
                        </div>
                        <instrument-picker
                            v-if="selectedMusician.name == musician.name"
                            @pickedInstruments="pickInstrumentsPerformance"
                            :label="'Performance'"
                        >
                        </instrument-picker>
                    </td>
                    <td>
                        <span v-if="!editing" class="inline-icon" :class="{activeicon: editing, checkicon: musician.pivot.guest_appearance, appicon: !musician.pivot.guest_appearance}"></span>
                        <input v-if="editing" type="checkbox" v-model="musician.pivot.guest_appearance"/>
                    </td>
                    <td>
                        <span v-if="!editing" class="inline-icon" :class="{activeicon: editing, checkicon: musician.pivot.leading_artist, appicon: !musician.pivot.leading_artist}"></span>
                        <input v-if="editing" type="checkbox" v-model="musician.pivot.leading_artist"/>
                    </td>
                    <td>
                        <span v-if="editing" class="inline-icon activeicon delete-icon" @click="removeMusician(musician)"></span>
                    </td>
                </tr>
            </tbody>
        </table>
        <musician-input
            v-if="editing"
            @MusicianAdded="addMusician"
            @change.native.stop=""
            @keyup.native.stop=""
            @musicianInputActivated="onMusicianInputActivated"
        ></musician-input>
    </div>
</template>

<script>
import MusicianInput from "./MusicianInput";
import InstrumentPicker from "./InstrumentPicker";
export default {
    name: "MusiciansComponent",
        data(){
          return{
            selectedMusician: {},
          }
     },
    components: {InstrumentPicker, MusicianInput},
    props: ['lineup', 'editing', 'performanceId'],
    watch:{
        editing: function (newval, oldval){
            //when newval==false then content has been saved
            if(!newval)
                this.selectedMusician={}
        }
    },
    methods: {
        onMusicianInputActivated(){
            this.selectedMusician = {};
        },
        editInstruments(musician){
            if(this.editing)
                this.selectedMusician = musician;
        },
        pickInstrumentsPerformance(instruments){
            this.selectedMusician.pivot.instrumentcode1 = '';
            this.selectedMusician.pivot.instrument1 = '';
            this.selectedMusician.pivot.instrumentcode2 = '';
            this.selectedMusician.pivot.instrument2 = '';
            this.selectedMusician.pivot.instrumentcode3 = '';
            this.selectedMusician.pivot.instrument3 = '';
            if(instruments.length > 0) {
                this.selectedMusician.pivot.instrumentcode1 = instruments[0].code;
                this.selectedMusician.pivot.instrument1 = instruments[0].name;
            }
            if(instruments.length > 1) {
                this.selectedMusician.pivot.instrumentcode2 = instruments[1].code;
                this.selectedMusician.pivot.instrument2 = instruments[1].name;
            }
            if(instruments.length > 2) {
                this.selectedMusician.pivot.instrumentcode3 = instruments[2].code;
                this.selectedMusician.pivot.instrument3 = instruments[2].name;
            }
        },
        pickInstrumentsMusician(instruments){
            this.selectedMusician.instruments = instruments;
        },
        showMusician(musician){
            if(!this.editing)
                location.href='/musician/' + musician.id + '/show';
        },
        addMusician(musician){
            this.selectedMusician={};
            this.$emit('MusicianAdded', musician);

        },
        removeMusician(musician){
            this.$emit('MusicianRemoved', musician);
        },
    }
}
</script>

<style scoped>
    table{
        width: 100%;
    }
    .leadingartist td{
        font-weight: bold;
    }
    p.musician{
        padding: 0;
        margin: 0;
    }
    p.link{
        color: blue;
        text-decoration: underline;
    }
    p.link:hover{
        cursor: pointer;
    }
    .instruments-cell{
        width: 100%;
        min-height: 30px;
    }
</style>
