<template>
    <div class="titled-box">
        <h4>Add to Line-up</h4>
        <table>
            <tr>
                <td class="musician-input-cell">
                    <input type="text" v-model="newMusician.name" placeholder="Naam" @keyup="findMusician" @focus="musicianInputActivated" ref="musicianNameInput"/>
                    <div class="popup-list" v-if="foundMusicians.length">
                        <span class="righttop-icon delete-icon" @click="foundMusicians=[]"></span>
                        <ul>
                            <li v-for="musician in foundMusicians" @click="selectMusician(musician)">{{musician.name}}</li>
                        </ul>
                    </div>
                </td>
                <td><instrument-picker
                    @pickedInstruments="pickInstrumentsMusician"
                    :selectedInstruments="newMusician.instruments"
                    @pickerActivated = musicianInputActivated
                    >
                </instrument-picker></td>
                <td><instrument-picker
                    @pickedInstruments="pickInstrumentsPerformance"
                    :label="'Performance'"
                    :selectedInstruments="selectedPerformanceInstruments"
                    @pickerActivated = musicianInputActivated
                >
                </instrument-picker></td>
                <td><input type="checkbox" value="1" v-model="newMusician.pivot.guest_appearance" @change="foundMusicians=[]" /></td>
                <td><input type="checkbox" value="1" v-model="newMusician.pivot.leading_artist" @change="foundMusicians=[]" /></td>
                <td><span v-if="musicianCanBeAdded" class="inline-icon activeicon add-musician-icon" @click="addMusician"></span></td>
            </tr>
        </table>
    </div>
</template>

<script>
import InstrumentPicker from "./InstrumentPicker";
export default {
    name: "MusicianInput",
    components: {InstrumentPicker},
    data: function(){
        return {
            newMusician: {pivot:{}, instruments:[]},
            foundMusicians: [],
            instrumentSelected: false,
            selectedPerformanceInstruments: [],
            filter: null
        }
    },
    computed:{
        musicianCanBeAdded: function (){
            return (this.newMusician.name && this.instrumentSelected);
        }
    },
    mounted(){
        this.clearNewMusician();
    },
    methods:{
        musicianInputActivated(){
            this.$emit('musicianInputActivated');
        },
        pickInstrumentsPerformance(instruments){
            if(instruments.length)
                this.instrumentSelected = true;

            this.selectedPerformanceInstruments = instruments;

            this.newMusician.pivot.instrumentcode1 = '';
            this.newMusician.pivot.instrument1 = '';
            this.newMusician.pivot.instrumentcode2 = '';
            this.newMusician.pivot.instrument2 = '';
            this.newMusician.pivot.instrumentcode3 = '';
            this.newMusician.pivot.instrument3 = '';

            if(this.selectedPerformanceInstruments.length > 0) {
                this.newMusician.pivot.instrumentcode1 = this.selectedPerformanceInstruments[0].code;
                this.newMusician.pivot.instrument1 = this.selectedPerformanceInstruments[0].name;
            }
            if(this.selectedPerformanceInstruments.length > 1) {
                this.newMusician.pivot.instrumentcode2 = this.selectedPerformanceInstruments[1].code;
                this.newMusician.pivot.instrument2 = this.selectedPerformanceInstruments[1].name;
            }
            if(this.selectedPerformanceInstruments.length > 2) {
                this.newMusician.pivot.instrumentcode3 = this.selectedPerformanceInstruments[2].code;
                this.newMusician.pivot.instrument3 = this.selectedPerformanceInstruments[2].name;
            }
        },
        pickInstrumentsMusician(instruments){
            this.newMusician.instruments = instruments;
            if(this.selectedPerformanceInstruments.length == 0)
                this.pickInstrumentsPerformance(instruments);
        },
        addMusician(){
            if(this.newMusician.name) {
                this.$emit('MusicianAdded', this.newMusician);
                this.clearNewMusician();
                this.$refs.musicianNameInput.focus();
            }
        },
        clearNewMusician(){
            axios.get('/api/musician/empty')
                .then(response => {
                    if(response.data.result=='success') {
                        this.newMusician = response.data.musician;
                        this.newMusician.pivot={guest_appearance: 0, leading_artist: 0};
                        this.instrumentSelected = false;
                        this.selectedPerformanceInstruments = [];
                    } else {
                        if(response.data.message)
                            alert(response.data.message);
                    }
                })
                .catch(error => {
                    if(error.response.status == 401){
                        location.href='';
                    } else {
                        alert(error);
                    }
                });
        },
        selectMusician(musician){
            this.foundMusicians=[];
            axios.get('/api/musician/' + musician.id)
                .then(response => {
                    if(response.data.result=='success') {
                        this.newMusician = response.data.musician;
                        this.newMusician.pivot={guest_appearance: 0, leading_artist: 0};
                        // pre-fill the instruments input field with the musician's instruments
                        this.pickInstrumentsMusician(this.newMusician.instruments);
                        this.pickInstrumentsPerformance([...this.newMusician.instruments]);
                        this.instrumentSelected = true;
                    } else {
                        if(response.data.message)
                            alert(response.data.message);
                    }
                })
                .catch(error => {
                    if(error.response.status == 401){
                        location.href='';
                    } else {
                        alert(error);
                    }
                });
         },
        findMusician(event){
            this.foundMusicians=[];
            this.filter={name: this.newMusician.name};
            axios.post('/api/musicians', this.filter)
                .then(response => {
                    if(response.data.result=='success') {
                       this.foundMusicians = response.data.musicians;
                    } else {
                        if(response.data.message)
                            alert(response.data.message);
                    }
                })
                .catch(error => {
                    if(error.response.status == 401){
                        location.href='';
                    } else {
                        alert(error);
                    }
                });
        }
    }
}
</script>

<style scoped>
    table{width: 100%}
    .musician-input-cell{
        position: relative;
    }
    input{width: 100%}
    th, td{min-width: 25px; padding: 3px 10px; background-color: green;}
    .righttop-icon{
        position: absolute;
        right: 0px;
    }
</style>
