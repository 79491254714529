<template>
    <div class="titled-box">
        <h4>Url's</h4>
        <table class="table-striped">
            <tr v-for="url in urls">
                <td>{{url.url_type}}</td>
                <td><a :href="urlWithLink(url)" target="_blank">{{url.title}}</a></td>
                <td><span v-if="editing" class="inline-icon activeicon delete-icon" @click="removeUrl(url)"></span></td>
            </tr>
            <tr v-if="editing">
                <td colspan="4">
                    <urls-input
                        @UrlAdded="addUrl"
                        @UrlRemoved="removeUrl"
                    ></urls-input>
                </td>
            </tr>
        </table>
    </div>
</template>

<script>
import UrlsInput from "./UrlsInput";
export default {
    name: "UrlsComponent",
    components: {UrlsInput},
    props: ['urls','editing'],
    methods: {
        urlWithLink: function (url){
            switch (url.url_type){
                case 'email':
                    return 'mailto:' + url.uri;
                    break;
                 default:
                     if(url.uri.includes('http')) {
                         return url.uri;
                     } else {
                         return 'https://' + url.uri;
                     }
            }
        },
        addUrl(url){
            this.$emit('UrlAdded', url);
        },
        removeUrl(url){
            this.$emit('UrlRemoved', url);
        },
    }
}
</script>

<style scoped>
    th,td{padding: 0; margin:0;}
</style>
