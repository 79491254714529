<template>
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-header">
                        <h4>Alle line-ups ({{total}})</h4>
                        <span class="righttop-icon add-icon"
                              @click.stop="addPerformance"
                              title="Line-up toevoegen"
                        ></span>
                    </div>

                    <div class="card-body">
                        <table class="table-striped selectable">
                            <thead>
                                <tr>
                                    <th class="sortable" @click.stop="sortList('title')">Title</th>
                                    <th class="sortable" @click.stop="sortList('type')">Type</th>
                                    <th class="sortable" @click.stop="sortList('band_name')">Band</th>
                                    <th class="sortable" @click.stop="sortList('date')">Datum</th>
                                    <th>Bezetting</th>
                                    <th>Label</th>
                                    <th class="sortable" @click.stop="sortList('ranking')">Ranking</th>
                                    <th></th>
                                </tr>
                                <tr @keyup.enter="readPerformances" @change="readPerformances">
                                    <td><input type="text" v-model="filter.title"/></td>
                                    <td><select v-model="filter.type">
                                        <option value=""></option>
                                        <option value="live">Live Optreden</option>
                                        <option value="band">Band</option>
                                        <option value="album">Album</option>
                                        <option value="livestream">Livestream</option>
                                    </select></td>
                                    <td><input type="text" v-model="filter.band_name"/></td>
                                    <td><input type="text" v-model="filter.date"/></td>
                                    <td><input type="text" v-model="filter.lineup"/></td>
                                    <td><input type="text" v-model="filter.label"/></td>
                                    <td><input type="text" v-model="filter.ranking"/></td>
                                    <td><span class="inline-icon search-icon" @click="readPerformances" title="Filter de line-ups"></span></td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="performance in performances" :key="performance.id" @click.stop="selectPerformance(performance)">
                                    <td>{{performance.title}}</td>
                                    <td>{{performance.type}}</td>
                                    <td>{{performance.band_name}}</td>
                                    <td>{{performance.date|dutchDate}}</td>
                                    <td>{{performance.musicians.length}}</td>
                                    <td>{{(performance.label && performance.label.name) || ''}}</td>
                                    <td>{{performance.ranking}}</td>
                                    <td nowrap="nowrap">
                                        <span class="inline-icon musician-icon" @click.stop="showLineUp(performance)" title="Toon de muzikanten voor deze line-up"></span>
                                        <span class="inline-icon delete-icon" @click.stop="deletePerformance(performance)" title="Verwijder deze line-up"></span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                     </div>
                    <div class="card-footer">
                        <pagination-component :pageNr="pageNr" :pageCount="pageCount" @goNext="goNext" @goPrev="goPrev" @goPage="goPage"></pagination-component>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal fade" tabindex="-1" role="dialog" id="line-up">
            <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h3>Line-up van {{selectedPerformance.title}}</h3>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <musicians-component
                            :lineup="selectedPerformance.musicians"
                            :editing="false"
                            :performanceId="selectedPerformance.id"
                        ></musicians-component>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-default" value="" @click="closeDialog">Annuleren</button>
                    </div>
                </div><!-- /.modal-content -->
            </div><!-- /.modal-dialog -->
        </div><!-- /.modal -->

        <PerformanceDialogComponent
            v-if="isDialogOpened"
            @close="isDialogOpened = false"
            @PerformanceAdded="performanceAdded"
        >
        </PerformanceDialogComponent>
    </div>
</template>

<script>
    import PerformanceDialogComponent from "./PerformanceDialogComponent";
    import MusiciansComponent from "./MusiciansComponent";
    import PaginationComponent from "./PaginationComponent";
    export default {
        components: {
            PerformanceDialogComponent, MusiciansComponent, PaginationComponent
        },
        data(){
            return {
                performances: [],
                filter: {},
                selectedPerformance: {},
                isDialogOpened: false,
                pageNr: 1,
                pageCount: 1,
                total: 0
            }
        },
        mounted() {
            this.readPerformances();
        },
        methods:{
            sortList(sortCol){
                if(this.filter.order==sortCol){
                    if(!this.filter.direction || this.filter.direction=='asc'){
                        this.filter.direction = 'desc';
                    } else {
                        this.filter.direction = 'asc';
                    }
                }
                this.filter.order = sortCol;
                this.readPerformances();
            },
            search(){
              location.href='search';
            },
            deletePerformance(performance){
                if(confirm('Deze line-up definitief verwijderen?')){
                    axios.delete('/api/performance/' + performance.id)
                        .then(response => {
                            if(response.data.result=='success') {
                               this.readPerformances();
                            } else {
                                if(response.data.message)
                                    alert(response.data.message);
                            }
                        })
                        .catch(error => {
                            if(error.response.status == 401){
                                location.href='';
                            } else {
                                alert(error);
                            }
                        });
                }
            },
            performanceAdded(performance) {
                // reload the list
                this.readPerformances();
            },
            selectPerformance: function (performance){
                location.href='performance/' + performance.id + '/show';
            },
            musicians: function (performance){
                location.href='musicians';
            },
            showLineUp: function (performance){
                this.selectedPerformance = performance;
                $("#line-up").modal("show");
            },
            closeDialog: function (){
                $("#line-up").modal("hide");
            },
            addPerformance(){
                this.isDialogOpened = true;
            },
            goNext(){
                this.filter.page = this.pageNr+1;
                this.readPerformances();
            },
            goPrev(){
                this.filter.page = this.pageNr-1;
                this.readPerformances();
            },
            goPage(newPage){
                this.filter.page = newPage
                this.readPerformances()
            },
            readPerformances(){
                let uri = '/api/performances'
                axios.get(uri, {params: this.filter})
                    .then(response => {
                        if(response.data.result=='success') {
                            this.performances = response.data.performances.data;
                            this.pageNr = response.data.performances.current_page;
                            this.pageCount = response.data.performances.last_page;
                            this.total = response.data.performances.total;
                        } else {
                            if(response.data.message)
                                alert(response.data.message);
                        }
                    })
                    .catch(error => {
                        if(error.response.status == 401){
                            location.href='';
                        } else {
                            console.log(error);
                        }
                    });
            }
        }
    }
</script>

<style scoped>
</style>
