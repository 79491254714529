<template>
    <table>
        <tr>
            <td>
                <input @keyup.stop="" @change.stop="" type="text" v-model="newUrl.uri" placeholder="Uri"/>
            </td>
            <td>
                <input @keyup.stop="" @change.stop="" type="text" v-model="newUrl.title" placeholder="Titel"/>
            </td>
            <td>
                <select @change.stop="onTypeChange" v-model="newUrl.url_type">
                    <option value="video">Video</option>
                    <option value="audio">Audio</option>
                    <option value="website">Website</option>
                    <option value="review">Recensie</option>
                    <option value="email">Email</option>
                    <option value="facebook">Facebook</option>
                    <option value="instagram">Instagram</option>
                    <option value="twitter">Instagram</option>
                    <option value="photos">Photo's</option>
                </select>
            </td>
            <td><span class="inline-icon activeicon add-icon" @click="addUrl"></span></td>
        </tr>
    </table>
</template>

<script>
export default {
    name: "UrlsInput",
    props: ['urls'],
    data(){
        return {
            newUrl: {},
        }
    },
    created(){
        this.urllist = this.urls;
    },
    mounted(){
        this.clearnewUrl();
    },
    methods:{
        onTypeChange(){
            switch (this.newUrl.url_type){
                case 'video': this.newUrl.title='Youtube videolink'; break;
                case 'audio': this.newUrl.title='Audio link'; break;
                case 'website': this.newUrl.title='Website'; break;
                case 'review': this.newUrl.title='Recensie'; break;
                case 'email': this.newUrl.title='E-mail'; break;
                case 'facebook': this.newUrl.title='Facebook pagina'; break;
                case 'instagram': this.newUrl.title='Instagram pagina'; break;
                case 'twitter': this.newUrl.title='Twitter pagina'; break;
                case 'photos': this.newUrl.title='Fotopagina'; break;
            }
        },
        addUrl(){
            console.log('url button');
            this.$emit('UrlAdded', this.newUrl);
            this.clearnewUrl();
        },
        clearnewUrl(){
            this.newUrl={uri: '', type:'youtube'}
        }
    }
}
</script>

<style scoped>
    input{width: 100%}
    th,td{padding: 0; margin:0;}
</style>
