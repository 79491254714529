<template>
     <div class="card">
        <div class="card-header">
            <h3>Zoeken van line-ups</h3>
            <div class="titled-box">
                <h4>Zoek op instrumenten</h4>
                <instrument-picker
                    @pickedInstruments="pickInstruments"
                    :label="'Kies instrumenten'"
                >
                </instrument-picker>
            </div>
            <tags-component :editing="true" :tags="tags" @tagsChanged="onTagsChanged"></tags-component>
        </div>

        <div class="card-body">
            <span>Gevonden: {{this.performances.length}}</span>
            <performances-component
                :performances="this.performances"
            >
            </performances-component>
        </div>
    </div>
</template>

<script>
import InstrumentPicker from "./InstrumentPicker";
import TagsComponent from "./TagsComponent";
import PerformancesComponent from "./PerformancesComponent";
export default {
    name: "SearchComponent",
    components: {InstrumentPicker, PerformancesComponent, TagsComponent},
    data(){
        return {
            instruments: [],
            selectedInstruments:[],
            performances:[],
            tags: []
        }
    },
    mounted(){
    },
    methods: {
        onTagsPicked(tags){
            this.tags = tags;
        },
        searchPerformances(){
            let searchData = {instruments: this.instruments, tags: this.tags};
            axios.post('/api/performance/search', searchData)
                .then(response => {
                    if(response.data.result=='success') {
                        this.performances = response.data.performances;
                    } else {
                        if(response.data.message)
                            alert(response.data.message);
                    }
                })
                .catch(error => {
                    if(error.response.status == 401){
                        location.href='';
                    } else {
                        alert(error);
                    }
                });
        },
        reset(){
            this.selectedInstruments = [];
            this.tags=[];
            this.performances=[];
            this.instruments=[];
        },
        pickInstruments(instruments){
            this.instruments = instruments;
            this.instruments.forEach(i=>{this.selectedInstruments += i.name + ', '});
            this.searchPerformances();
        },
        onTagsChanged(tags){
            this.tags=tags;
            this.searchPerformances();
        }
    }
}
</script>

<style scoped>

</style>
