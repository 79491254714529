<template>
    <div class="performances titled-box">
        <h4>Line-ups</h4>
        <table class="table-striped">
            <tbody>
                <tr v-for="performance in performances">
                    <td>
                        <a href="#" @click="showPerformance(performance)">{{performance.title}}</a>
                    </td>
                    <td>{{performance.type}}</td>
                    <td>{{performance.band_name}}</td>
                    <td>
                        <span v-if="performance.pivot.instrumentcode1">{{performance.pivot.instrument1}}</span>
                        <span v-if="performance.pivot.instrumentcode2">, {{performance.pivot.instrument2}}</span>
                        <span v-if="performance.pivot.instrumentcode3">, {{performance.pivot.instrument3}}</span>
                    </td>
                    <td>{{performance.date|dutchDate}}</td>
                </tr>
            </tbody>
        </table>
    </div>
</template>


<script>
export default {
    name: "MusicianPerformancesComponent",
    props: ['performances'],
    methods: {
        showPerformance: function (performance){
            location.href='/performance/' + performance.id + '/show';
        }
    }
}
</script>

<style scoped>

</style>
