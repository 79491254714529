<template>
    <div class="pagination">
        <span class="hyperlink" v-if="pageNr>1" @click="goPrev">&lt;vorige</span>&nbsp;
        Pagina <input class="page-nr" v-model="curPage" @change="goPage" /> van {{pageCount}}
        &nbsp;<span v-if="pageCount>pageNr" class="hyperlink" @click="goNext">volgende&gt;</span>
    </div>
</template>

<script>
export default {
    name: "PaginationComponent",
    props: ['pageNr', 'pageCount'],
    data: function (){
        return { curPage: 1 }
    },
    created(){
       this.curPage = this.pageNr;
    },
    watch: {
        pageNr: function (newval, oldval){
            this.curPage = newval;
        }
    },
    methods: {
        goPrev(){this.$emit('goPrev')},
        goNext(){this.$emit('goNext')},
        goPage(){this.$emit('goPage', this.curPage)},
    }
}
</script>

<style scoped>
input{width: 100%;}
.hyperlink{
    color: blue;
}
.hyperlink:hover{
    cursor: pointer;
}
input.page-nr{
    width: 40px;
    margin: 0px 5px;
    position: relative;
    top: -4px;
    text-align: center;
}

</style>
