<template>
    <div class="performances titled-box">
        <h4>{{label}}</h4>
        <table class="table-striped">
            <tr v-for="performance in performances">
                <td>
                    <a href="#" @click="showPerformance(performance)">{{performance.title}}</a>
                </td>
                <td>{{performance.type}}</td>
                <td>{{performance.band_name}}</td>
                <td>
                    {{performance|instrumentList}}
                </td>
                <td>
                    {{performance.bezetting}}
                </td>
                <td>
                    {{performance.ranking}}
                </td>
                <td>{{performance.date|dutchDate}}</td>
            </tr>
        </table>
    </div>
</template>


<script>
export default {
    name: "PerformancesComponent",
    props: {
        performances:
            {type: Array, default: function(){return []}},
        label:
            {type: String, default: 'Line-ups' }
    },
    filters:{
        instrumentList: function (performance){
            let list = '';
            !performance.i1 || (list = performance.i1);
            !performance.i2 || (list += ',' + performance.i2);
            !performance.i3 || (list += ',', performance.i3);
            //remove duplicates by converting to Set
            let theList = [... new Set(list.split(','))].sort();
            return theList.join(', ');
        }
    },
    methods: {
        showPerformance: function (performance){
            location.href='/performance/' + performance.id + '/show';
        }
    }
}
</script>

<style scoped>

</style>
