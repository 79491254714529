<template>
    <div class="card">
        <div class="card-header"><h3>Muzikanten ({{this.musicians.length}})</h3></div>
        <div class="card-body">
            <table class="table-striped selectable">
                <thead>
                    <tr>
                        <th>Naam</th>
                        <th>Instrumenten</th>
                        <th></th>
                    </tr>
                    <tr>
                        <th>
                            <input type="text" v-model="filter.name" @keyup.enter="getMusicians" />
                            <span class="inline-icon delete-icon" v-if="filter.name" @click="clearName" title="Reset het naam-filter"></span>
                        </th>
                        <th>
                            <instrument-picker
                                @pickedInstruments="pickInstrumentsMusician"
                                :selectedInstruments="filter.instruments"
                            >
                            </instrument-picker>
                        </th>
                        <th>
                            <span class="btn-link" @click="getMusicians">Zoeken</span>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="musician in musicians" :key="musician.id" @click.stop="selectMusician(musician)">
                        <td>
                            <p>
                                {{musician.name}}
                            </p>
                        </td>
                        <td>
                            {{musician.instruments}}
                        </td>
                        <td></td>
                    </tr>
                    <tr v-if="searching">
                        <td>Zoeken...</td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr v-if="musicians.length==0">
                        <td>Geen muzikanten</td>
                        <td></td>
                        <td></td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="card-footer">
        </div>
    </div>
</template>

<script>
import InstrumentPicker from "./InstrumentPicker";
import MusicianInput from "./MusicianInput";
import PaginationComponent from "./PaginationComponent";
export default {
    name: "MusiciansComponent",
    data(){ return {
            musicians: [],
            filter: {},
            pageNr: 1,
            pageCount: 1,
            total: 0,
            searching: false,
        }
    },
    components: {MusicianInput, PaginationComponent, InstrumentPicker},
    mounted(){
        this.getMusicians();
    },
    methods: {
        clearName(){
            this.filter.name='';
            this.getMusicians();
        },
        clearSearch(){
            this.musicians = [];
        },
        pickInstrumentsMusician(instruments){
            this.filter.instruments = instruments;
            this.getMusicians();
        },
        selectMusician(musician){
            // click on musician in table
            location.href='musician/' + musician.id + '/show';
        },
        goNext(){
            this.filter.page = this.pageNr+1;
            this.getMusicians();
        },
        goPrev(){
            this.filter.page = this.pageNr-1;
            this.getMusicians();
        },
        goPage(newPage){
            this.filter.page = newPage
            this.getMusicians()
        },
        getMusicians(){
            this.searching = true;
            axios.post('/api/musicians', this.filter)
                .then(response => {
                    this.searching = false;
                    if(response.data.result=='success') {
                        this.musicians= response.data.musicians;
                        //this.pageNr = response.data.musicians.current_page;
                        //this.pageCount = response.data.musicians.last_page;
                        //this.total = response.data.musicians.total;
                    } else {
                        if(response.data.message)
                            alert(response.data.message);
                    }
                })
                .catch(error => {
                    if(error.response.status == 401){
                        location.href='';
                    } else {
                        alert(error);
                    }
                });
        }
    }
}
</script>

<style scoped>
    input{width: 90%}
</style>
