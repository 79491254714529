<template>
    <div class="instrument-list">
         <span
            v-if="instrument.children.length"
            class="inline-icon"
            :class="{'caret-down-icon': showChildren, 'caret-right-icon': !showChildren}"
            @click="toggleGroup()"
        ></span>
        <span :class="{branchisselected: branchSelected}">{{instrument.name}}</span>
        <input type="checkbox" :name="instrument.code" :value="instrument.code" :checked="instrumentSelected" @click="toggleInstrument(instrument)" />
        <instruments-tree
            v-for="instrument in instrument.children"
            v-if="showChildren"
            :key="instrument.id"
            :instrument="instrument"
            @instrumentToggled="toggleInstrument"
            :selectedInstruments="selectedInstruments"
        >
        </instruments-tree>
    </div>
</template>

<script>
export default {
    name: "InstrumentsTree",
    props: ['instrument', 'selectedInstruments'],
    data: function(){return{
        showChildren: false,
        branchSelected: false,
    }},
    created(){
        this.branchSelected = this.instrumentSelected;
    },
    computed:{
        instrumentSelected: function (){
            return (this.selectedInstruments.find(i=>{return i.code == this.instrument.code}) != undefined);
        }
    },
    methods:{
        toggleInstrument(instrument){
            this.$emit('instrumentToggled', instrument);
            this.branchSelected = !this.branchSelected;
        },
        toggleGroup(){
            this.showChildren = !this.showChildren;
        }
    }
}
</script>

<style scoped>
    input[type='checkbox']{
        width: 40px;
    }
    .instrument-list{
        padding-left: 50px;
    }
    .branchisselected{
        font-weight: bold;
    }
</style>
