<template>
    <div>
        <input type="text" class="form-control" placeholder="Naam band / formatie" @keyup="listBands" v-model="bandNameInput"/>
        <div class="popup-list" v-if="editMode" id="bandlist">
            <span class="righttop-icon delete-icon" @click="acceptBandname"></span>
            <ul>
                <li v-for="performance in foundPerformances" :key="performance.id" @click="selectPerformance(performance)">
                    {{performance.band_name}} ({{performance.date}})
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
    export default {
        name: "LineupComponent",
        data(){
            return {
                bandNameInput: '',
                filter: [],
                foundPerformances: [],
                editMode: false
            }
        },
        props: ['bandName'],
        mounted() {
            this.bandNameInput = this.bandName;
        },
        methods: {
            acceptBandname: function () {
                this.foundPerformances=[];
                this.editMode = false;
                this.$emit('nameEntered', this.bandNameInput);
            },
            selectPerformance: function(p) {
                this.editMode = false;
                this.$emit('performanceSelected', p)
            },
            listBands: function (e) {
                this.editMode = true;
                this.filter={bandName: this.bandNameInput};
                if (!this.bandNameInput) return;
                axios.get('/api/performances/band/' + this.bandNameInput)
                    .then(response => {
                        if(response.data.result=='success') {
                            this.foundPerformances = response.data.performances;
                        } else {
                            if(response.data.message)
                                alert(response.data.message);
                        }
                    })
                    .catch(error => {
                        if(error.response.status == 401){
                            location.href='';
                        } else {
                            alert(error);
                        }
                    });
            }
        }
    }


</script>

<style scoped>
    #bandlist {
    }
</style>
