<template>
    <div>
        <div style="position: relative">
            <input class="instrumentpick" type="text" :placeholder="label" v-model="selectedInstrumentNames" @focus="showDialog"/>
            <span v-if="pickedInstruments.length" class="active-icon embedded-icon delete-icon" @click="reset"></span>
        </div>
        <div v-if="showModal">
            <transition name="modal">
                <div class="modal-mask">
                    <div class="modal-wrapper">
                        <div class="modal-dialog" role="document">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title">Kies instrumenten voor {{label}}</h5>
                                </div>
                                <div class="modal-body">
                                    <instruments-tree
                                        v-for="instrument in instruments"
                                        :key="instrument.id"
                                        :instrument="instrument"
                                        @instrumentToggled="toggleInstrument"
                                        :selectedInstruments="pickedInstruments"
                                    >
                                    </instruments-tree>
                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-secondary" @click="cancelChanges">Annuleren</button>
                                    <button type="button" class="btn btn-primary" @click="saveChanges">Opslaan</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
import InstrumentsTree from "./InstrumentsTree";
export default {
    name: "InstrumentPicker",
    components: {InstrumentsTree},
    props: {
        label: {
            type: String,
            default: 'Muzikant'
        },
        selectedInstruments:{
            type: Array,
            default: function () {
                return [];
            }
        }
    },
    data: function(){ return {
            instruments: [],
            showModal: false,
            selectedInstrumentNames: '',
            pickedInstruments: [],
        }
    },
    created(){
        this.getInstrumentNames();
        this.pickedInstruments = [...this.selectedInstruments];
    },
    mounted() {
        axios.get('/api/instrumenttree')
            .then(response => {
                if(response.data.result=='success') {
                    this.instruments = response.data.instruments;
                } else {
                    if(response.data.message)
                        alert(response.data.message);
                }
            })
            .catch(error => {
                if(error.response.status == 401){
                    location.href='';
                } else {
                    alert(error);
                }
            });
    },
    watch: {
        selectedInstruments(newval, oldval){
            this.pickedInstruments = newval;
        },
        pickedInstruments(){
            this.getInstrumentNames();
        }

    },
    methods:{
        reset(){
            this.pickedInstruments = [];
            this.saveChanges();
        },
        getInstrumentNames(){
            this.selectedInstrumentNames = '';
            this.pickedInstruments.forEach(i=>{this.selectedInstrumentNames += i.name + ', '});
        },
        toggleInstrument(instrument){
            // handle checkbox click on an instrument
            let instrumentWasSelected = this.pickedInstruments.find(i=>{return i.id == instrument.id});
            if(instrumentWasSelected){
                this.pickedInstruments = this.pickedInstruments.filter(i=>{return i.id != instrument.id});
            } else {
                this.pickedInstruments.push(instrument);
            }
        },
        showDialog(){
            this.showModal = true;
            this.$emit('pickerActivated');
        },
        saveChanges(){
            this.$emit('pickedInstruments', this.pickedInstruments);
            this.showModal = false;
        },
        cancelChanges(){
             // restore the original selected instruments
            this.showModal = false;
        },
     }
}
</script>

<style scoped>
input[type='checkbox']{
    width: 30px;
}
input[type='text']{
    width: 100%;
}
.embedded-icon{
    position: absolute;
    right: 0px;
    top: 0px;
    width: 34px;
    height: 34px;
    background-size: 21px;
    background-repeat: no-repeat;
    background-position: 3px;
}
.no-bullets li *{
    display: inline-block;
}

.modal-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);
    display: table;
    transition: opacity .3s ease;
}

.modal-wrapper {
    display: table-cell;
    vertical-align: middle;
}
</style>
