/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */
import Popper from 'popper.js'

require('./bootstrap');

window.Vue = require('vue');
Vue.filter('dutchDate', val => {
    if (val) {
        return moment(String(val)).format('DD-MM-YYYY')
    }
});

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))


Vue.component('home-component', require('./components/HomeComponent.vue').default);
Vue.component('performance-component', require('./components/PerformanceComponent.vue').default);
Vue.component('musician-component', require('./components/MusicianComponent.vue').default);
Vue.component('musicianlist-component', require('./components/MusicianlistComponent.vue').default);
Vue.component('search-component', require('./components/SearchComponent.vue').default);

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

const app = new Vue({
    el: '#app',
});
import moment from 'moment';
//window.EventBus = new Vue();
