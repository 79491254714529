<template>
    <div class="card">
        <div class="card-header">
            <h4 v-if="!editmode">{{musician.name}}</h4>
            <input type="text" v-model="musician.name" v-if="editmode" @change="changed" />
            <span class="righttop-icon edit-icon" @click="startEditMode" v-if="!editmode"></span>
            <span class="righttop-icon checkicon" @click="savechanges" v-if="contentChanged"></span>
            <span class="righttop-icon delete-icon" @click="cancelchanges" v-if="editmode"></span>
        </div>
        <div class="card-body">
            <div class="row justify-content-center">
                <div class="col-md-7">
                    <table @keyup="changed" @change="changed">
                        <tr>
                            <td>Instrumenten</td>
                            <td>
                                <span v-if="!editmode">
                                    <span v-for="instrument in musician.instruments">{{instrument.name}}, </span>
                                </span>
                                <instrument-picker
                                    v-if="editmode"
                                    @pickedInstruments="pickInstruments"
                                    :label="'Muzikant'"
                                    :selectedInstruments="musician.instruments"
                                    @change.native="changed"
                                ></instrument-picker>
                            </td>
                        </tr>
                        <tr>
                            <td>Telefoon</td>
                            <td>
                                <span v-if="!editmode">{{musician.telephone}}</span>
                                <input type="text" v-model="musician.telephone" v-if="editmode" />
                            </td>
                        </tr>
                        <tr>
                            <td>Mobiel</td>
                            <td>
                                <span v-if="!editmode">{{musician.mobile}}</span>
                                <input type="text" v-model="musician.mobile" v-if="editmode" />
                            </td>
                        </tr>
                        <tr>
                            <td>Nationaliteit</td>
                            <td>
                                <span v-if="!editmode">{{musician.nationality}}</span>
                                <input type="text" v-model="musician.nationality" v-if="editmode" />
                            </td>
                        </tr>
                        <tr>
                            <td>Land</td>
                            <td>
                                <span v-if="!editmode">{{musician.country}}</span>
                                <input type="text" v-model="musician.country" v-if="editmode" />
                            </td>
                        </tr>
                        <tr>
                            <td>Plaats</td>
                            <td>
                                <span v-if="!editmode">{{musician.city}}</span>
                                <input type="text" v-model="musician.city" v-if="editmode" />
                            </td>
                        </tr>
                        <tr>
                            <td>Geboortejaar</td>
                            <td>
                                <span v-if="!editmode">{{musician.year_of_birth}}</span>
                                <input type="text" v-model="musician.year_of_birth" v-if="editmode" />
                            </td>
                        </tr>
                        <tr>
                            <td>Jaar van overlijden</td>
                            <td>
                                <span v-if="!editmode">{{musician.year_of_death}}</span>
                                <input type="text" v-model="musician.year_of_death" v-if="editmode" />
                            </td>
                        </tr>
                       <tr>
                            <td>Info</td>
                            <td>
                                <span v-if="!editmode">{{musician.bio}}</span>
                                <textarea type="text" v-model="musician.bio" v-if="editmode" />
                            </td>
                        </tr>
                    </table>
                </div>
                <div class="col-md-5">
                    <div class="titled-box">
                        <h4>Photo</h4>
                        <input v-if="editmode" ref="photoInput" type="file" accept="image/*" capture="camera" @change="readImage" />
                        <img :src="musician.photoref" />
                    </div>
                </div>
            </div>

            <urls-component
                :urls="musician.urls"
                :editing="editmode"
                @UrlAdded="addUrl"
                @UrlRemoved="removeUrl"
            ></urls-component>

            <musician-performances-component
                :performances="musician.performances"
            >
            </musician-performances-component>
    </div>
    </div>
</template>

<script>
import UrlsComponent from "./UrlsComponent";
import MusicianPerformancesComponent from "./MusicianPerformancesComponent";
import InstrumentPicker from "./InstrumentPicker";
export default {
    name: "MusicianComponent",
    props: ['musicianId'],
    components: {UrlsComponent, MusicianPerformancesComponent, InstrumentPicker},
    data(){
        return {
            musician: {},
            contentChanged: false,
            editmode: false,
            errors: [],
        }
    },
    mounted() {
        this.readMusician();
    },
    methods: {
        pickInstruments(instruments){
            this.musician.instruments = instruments;
        },
        readImage(){
            let reader = new FileReader();
            let vm=this;
            reader.onload = function (){
                const data = {imagedata: reader.result};
                axios.post('/api/musician/' + vm.musician.id + '/photo', data)
                    .then(response => {
                        if(response.data.result=='success') {
                            vm.musician = response.data.musician;
                            vm.changed();
                        } else {
                            if(response.data.message)
                                alert(response.data.message);
                        }
                    })
                    .catch(error => {
                        if(error.response.status == 401){
                            location.href='';
                        } else {
                            alert(error);
                        }
                    });
            }
            reader.readAsDataURL(this.$refs.photoInput.files[0]);
        },
        startEditMode(){
            axios.get('/api/locations')
                .then(response => {
                    if(response.data.result=='success') {
                        this.locations = response.data.locations;
                        this.labels = response.data.labels;
                        this.editmode=true;
                    } else {
                        if(response.data.message)
                            alert(response.data.message);
                    }
                })
                .catch(error => {
                    if(error.response.status == 401){
                        location.href='';
                    } else {
                        alert(error);
                    }
                });
        },
        changed(){
            this.contentChanged = true;
            this.errors = [];
        },
        cancelchanges(){
            this.readMusician();
        },
        readMusician(){
            axios.get('/api/musician/' + this.musicianId)
                .then(response => {
                    if(response.data.result=='success') {
                        this.musician = response.data.musician;
                        this.contentChanged = false;
                        this.editmode = false;
                        this.selectedMusicianInstruments = '';
                    } else {
                        if(response.data.message)
                            alert(response.data.message);
                    }
                })
                .catch(error => {
                    if(error.response.status == 401){
                        location.href='';
                    } else {
                        alert(error);
                    }
                });
        },
        savechanges(){
            this.errors = [];
            axios.patch('/api/musician/' + this.musician.id, this.musician)
                .then(response => {
                    if(response.data.result=='success') {
                        this.contentChanged = false;
                        this.editmode = false;
                        this.musician = response.data.musician;
                        this.selectedMusicianInstruments = '';
                    } else {
                        if(response.data.errors) this.errors = response.data.errors;
                        if(response.data.message) alert(response.data.message);
                    }
                })
                .catch(error => {
                    if(error.response.status == 401){
                        location.href='';
                    } else {
                        alert(error);
                    }
                });
        },
        removeUrl(url) {
            if(url.id) {
                axios.delete('/api/musician/' + this.musician.id + '/url/' + url.id)
                    .then(response => {
                        if (response.data.result == 'success') {
                            this.musician.urls = this.musician.urls.filter(u => {
                                return (url.id != u.id)
                            });
                        } else {
                            if (response.data.message)
                                alert(response.data.message);
                        }
                    })
                    .catch(error => {
                        if (error.response.status == 401) {
                            location.href = '';
                        } else {
                            alert(error);
                        }
                    });
            } else {
                this.musician.urls = this.musician.urls.filter(u => {
                    return (url.uri != u.name)
                });
            }
        },
        addUrl(url){
            axios.post('/api/musician/' + this.musician.id + '/url', url)
                .then(response => {
                    if(response.data.result=='success') {
                        this.musician.urls.push(response.data.url);
                        this.changed();
                    } else {
                        if(response.data.message)
                            alert(response.data.message);
                    }
                })
                .catch(error => {
                    if(error.response.status == 401){
                        location.href='';
                    } else {
                        alert(error);
                    }
                });
        },
    }
}
</script>

<style scoped>

</style>
